import React from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { files } from "../mobx/files";
import { ui } from "../mobx/ui";

const PageThumbnails = () => {
  const PageClick = (pageId) => {
    files.updateActiveFile({
      ...files.activeFileIndex,
      pageId: pageId,
    });
  };

  const pagesContainerRef = React.useRef(null);

  const pagesVirtualizer = useVirtualizer({
    count: files.getActiveFile(ui.getCurrentUploadId).pagesConditions?.length,
    getScrollElement: () => pagesContainerRef.current,
    estimateSize: () => 52,
  });

  /*
  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: 2,
    getScrollElement: () => pagesContainerRef.current,
    estimateSize: () => 156,
  });
  */

  return (
    <div
      ref={pagesContainerRef}
      className="overflow-y-scroll pt-5"
      style={{ height: "calc(60vh - 64px)" }}
    >
      <div
        className="relative"
        style={{
          height: pagesVirtualizer.getTotalSize(),
        }}
      >
        {pagesVirtualizer.getVirtualItems().map((virtualRow) => {
          const index = virtualRow.index;
          if (
            index >
            files.getActiveFile(ui.getCurrentUploadId).pagesConditions.length -
              1
          ) {
            return <></>;
          }

          const page = files.getActiveFile(ui.getCurrentUploadId)
            .pagesConditions[index];

          return (
            <div
              ref={(el) => {
                virtualRow.measureElement(el);
              }}
              key={`${files.activeFileIndex}-${virtualRow.index}-${virtualRow.key}`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: 52,
                transform: `translateX(0px) translateY(${virtualRow.start}px)`,
              }}
              className={`hover:bg-primary_light rounded pt-4 pb-3 px-2 cursor-pointer w-full ${
                index === files.getActivePageArrayIndex(ui.currentUploadId) &&
                `bg-primary_light`
              }`}
              onClick={() => {
                PageClick(page.pageId);
              }}
            >
              <p className="">
                {files.multiSelect ? (
                  <label className="px-3">
                    <input
                      type="checkbox"
                      checked={files.selectedPages.includes(page.pageId)}
                      onChange={($ev) => {
                        if ($ev.target.checked) {
                          files.updateSelectedPages([
                            ...files.selectedPages,
                            page.pageId,
                          ]);
                        } else {
                          files.updateSelectedPages(
                            files.selectedPages.filter((p) => p !== page.pageId)
                          );
                        }
                      }}
                    />
                  </label>
                ) : (
                  <></>
                )}
                Page {index + 1}
              </p>

              {page.conditions.length > 0 && (
                <span className="small-tag absolute right-3 bottom-3">
                  {page.conditions.length}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PageThumbnails;
