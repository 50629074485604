import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";

// :: UAT/tests keys ::
// const firebaseConfig = {
//   apiKey: "AIzaSyAM-6VsZB-steMdmDGgP7Re07Fij84N7oM",
//   authDomain: "claims-clipping-uat.firebaseapp.com",
//   projectId: "claims-clipping-uat",
//   storageBucket: "claims-clipping-uat.appspot.com",
//   messagingSenderId: "393191945887",
//   appId: "1:393191945887:web:15bea6f86b64bf837314aa",
// };

// :: production keys ::
const firebaseConfig = {
  apiKey: "AIzaSyC5o9-XtaI1pPwBnI8kZVHj5XQM8eKKCWU",
  authDomain: "claims-clipping.firebaseapp.com",
  projectId: "claims-clipping",
  storageBucket: "claims-clipping.appspot.com",
  messagingSenderId: "836992489591",
  appId: "1:836992489591:web:2ca383b533d2733764ce72",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };
