import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import FeatherIcon from "feather-icons-react";
import { PDFDocument } from "pdf-lib";

import Logo from "../assets/Logo.png";

import { Button, Alert, CircularProgress, Chip, Dialog } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// mobx
import { toJS } from "mobx";
import { files } from "../mobx/files";
import { ui } from "../mobx/ui";
import { conditions } from "../mobx/conditions";
import { observer } from "mobx-react";

// select
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

// zip
import { saveAs } from "file-saver";
import EditConditionList from "./EditConditionList";

import PageThumbnails from "./PageThumbnails";
import { useCallback } from "react";
import FileToArrayBuffer from "../functions/FileToArrayBuffer";

const TagFiles = observer(({ ChangeStep }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [canDownload, setcanDownload] = useState(false);
  const [editConditionShown, setEditConditionShown] = useState(false);
  const [editConditionData, setEditConditionData] = useState([]);
  const [zoomIn, setZoomIn] = useState(false);
  const [toggleExportSuccess, setToggleExportSuccess] = useState(false);

  useEffect(() => {
    CheckHasConditions();
  }, []);

  const handleToggleModalSuccess = useCallback(() => {
    setToggleExportSuccess((state) => !state);
  }, []);

  const CheckHasConditions = () => {
    let hasConditions = files.getFiles(ui.getCurrentUploadId).some((file) => {
      return file.pagesConditions.some((condition) => {
        return condition.conditions.length > 0;
      });
    });
    hasConditions ? setcanDownload(true) : setcanDownload(false);
  };

  const FileNameClick = (fileId, pageId) => {
    files.updateActiveFile({ fileId: fileId, pageId: pageId });
  };

  const PageClick = (pageId) => {
    files.updateActiveFile({
      ...files.activeFileIndex,
      pageId: pageId,
    });
  };

  // QUESTION: should i put this function here?
  const DownloadPdfs = async () => {
    setDownloadLoading(true);

    files.formatExportFilesInfo(ui.currentUploadId);

    await Promise.all(
      files.getExportFilesInfo.map(async (condition) => {
        // final output pdf

        const pdfOutput = await PDFDocument.create();
        const pdfTitle = condition.conditionName;

        pdfOutput.setTitle(pdfTitle);

        await Promise.all(
          condition.includedFiles.map(async (file) => {
            const existingPdfBytes = await FileToArrayBuffer(file.file);
            const pdfDoc = await PDFDocument.load(existingPdfBytes, {
              ignoreEncryption: true,
            });

            await Promise.all(
              file.pages.map(async (page) => {
                // each page inside same file

                const [existingPage] = await pdfOutput.copyPages(pdfDoc, [
                  files.getIndexPageByPageAndFileId(
                    ui.currentUploadId,
                    file.fileId,
                    page
                  ),
                ]);

                pdfOutput.addPage(existingPage);
              })
            );
          })
        );

        const pdfOutputUri = await pdfOutput.saveAsBase64();
        files.updateExportFiles([
          ...files.getExportFiles,
          {
            uri: pdfOutputUri,
            name: pdfTitle,
            isPAMT: condition.conditionIsPAMT,
          },
        ]);
      })
    )
      .then(() => {
        setErrorMessage("");
        // zip
        const zip = require("jszip")();

        files.getExportFiles.forEach((file) => {
          let folder = "";
          if (file.isPAMT) {
            folder = "PAMT/PAMT - ";
          }

          zip.file(`${folder}${file.name}.pdf`, file.uri, {
            base64: true,
          });
        });

        const patientName = files.getPatientName(ui.currentUploadId);

        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, `${patientName}.zip`);
          setDownloadLoading(false);
        });

        handleToggleModalSuccess();

        // end zip
      })
      .catch(function (err) {
        console.log(JSON.stringify(err, null, 2));
        setDownloadLoading(false);
        setErrorMessage(err.message ?? "Unknown Error");
      });
  };

  function openEditCondition() {
    setEditConditionData(toJS(conditions.conditions));
    setEditConditionShown(true);
  }

  async function closeEditCondition() {
    await conditions.getConditionsFromDatabase();

    const activeConditions = files
      .getActiveConditions(ui.currentUploadId)
      .map((activeCondition) => {
        const f = conditions.getConditions.find(
          (condition) => condition.value === activeCondition.value
        );
        if (!f) {
          return null;
        }

        return {
          label: f.label,
          value: f.value,
          isPAMT: f.isPAMT,
        };
      })
      .filter((f) => !!f);

    files.updateActiveFileConditions(ui.currentUploadId, activeConditions);
    CheckHasConditions();

    setEditConditionShown(false);
    setEditConditionData([]);
  }

  // ChangeStep(1);
  const filterOptions = (candidate, input) => {
    const normalized = input.toUpperCase();
    if (normalized) {
      if (normalized === "PAMT") {
        return candidate.data.isPAMT;
      }

      return candidate.label.toUpperCase().includes(normalized);
    }

    return true;
  };

  useEffect(() => {
    const currentFile = files.getFiles(ui.getCurrentUploadId);

    files.updateActiveFile({
      fileId: currentFile[0]?.id,
      pageId: currentFile[0]?.pagesConditions[0].pageId,
    });
  }, []);

  return (
    <div className="">
      <EditConditionList
        shown={editConditionShown}
        data={editConditionData}
        handleClose={closeEditCondition}
      />

      <Dialog maxWidth={"sm"} fullWidth open={toggleExportSuccess}>
        <div className="p-5">
          <button
            onClick={handleToggleModalSuccess}
            className="bg-light_grey hover:bg-border_grey transition-all float-right  cursor-pointer rounded-full w-[2rem] h-[2rem] flex justify-center items-center cursor-pointer"
          >
            <FeatherIcon icon={"x"} size={20} />
          </button>
          <div className="flex mt-5 flex-col items-center">
            <FeatherIcon icon="check-circle" size={68} className="text-green" />
            <h1 className="label text-2xl mt-3">Success</h1>
            <p className="mt-1 text-md text-stone-500	">
              Your file has been downloaded.
            </p>
            <button
              onClick={() => {
                ChangeStep(0);
              }}
              className="bg-primary_light text-primary rounded p-2 mt-7"
            >
              Go Back To Home Page
            </button>
          </div>
        </div>
      </Dialog>

      <div
        className={
          "grid h-[100vh] " +
          (zoomIn
            ? "grid-cols-[minmax(0,70fr)_minmax(0,30fr)]"
            : "grid-cols-[410px_minmax(0,45fr)_minmax(0,30fr)]")
        }
      >
        <div className={"bg-light_grey " + (zoomIn ? "hidden" : "")}>
          {/* documents */}
          <div className="flex items-center justify-center p-5 h-[10vh]">
            <img src={Logo} className="w-[200px]" alt="" />
          </div>
          <div className="px-9 pt-5 pb-2 border-b border-b-border_grey h-[30vh] ">
            <p className="label flex items-center gap-x-2">
              Documents{" "}
              <span className="small-tag">
                {files.getFiles(ui.getCurrentUploadId).length}
              </span>
            </p>
            <div className="mt-5 max-h-[20vh] overflow-y-scroll">
              {files.getFiles(ui.getCurrentUploadId).length &&
                files.getFiles(ui.getCurrentUploadId).map((file, index) => {
                  return (
                    <p
                      className={`py-1 px-4  rounded hover:bg-primary_light transition-all cursor-pointer my-1 ${
                        file.id === files.activeFileIndex.fileId &&
                        `bg-primary_light`
                      }`}
                      onClick={() => {
                        FileNameClick(file.id, file.pagesConditions[0].pageId);
                      }}
                      key={index}
                    >
                      {file.name}
                    </p>
                  );
                })}
            </div>
          </div>
          {/* end documents */}

          {/* pages */}
          <div className="px-9 pt-5 py-5">
            <div className="flex justify-between">
              <p className="label flex items-center gap-x-2">
                Pages{" "}
                <span className="small-tag">
                  {files.getActiveFilePageCount(ui.getCurrentUploadId)}
                </span>
              </p>

              <Button
                variant="contained"
                size="small"
                disableElevation
                onClick={() => files.updateMultiSelect(!files.multiSelect)}
              >
                {files.multiSelect ? "Disable" : "Enable"} multiple pages
              </Button>
            </div>

            {!zoomIn && <PageThumbnails />}
          </div>
          {/* end pages */}
        </div>

        <div className="bg-white w-[100%] flex px-9 pt-5">
          <div className="flex w-[100%] justify-between h-full max-h-[95vh] flex-col">
            {errorMessage?.length > 0 && (
              <div className="mb-5">
                <Alert
                  severity="error"
                  onClose={() => {
                    setErrorMessage("");
                  }}
                  className="my-3"
                >
                  {errorMessage}
                </Alert>
              </div>
            )}
            <div className="flex items-center justify-between">
              {/* Go back */}
              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  ChangeStep(1);
                }}
              >
                <FeatherIcon
                  icon="chevron-left"
                  size={20}
                  className="text-body_grey pr-1"
                />
                <p className="label opacity-70 duration-200 ease-in-out hover:opacity-100">
                  Back to upload
                </p>
              </div>

              {/* Page navigation */}
              <div className="bottom-5 drop-shadow rounded overflow-hidden bg-white flex items-center">
                <button
                  type="button"
                  disabled={
                    files.getActivePageArrayIndex(ui.currentUploadId) <= 0
                  }
                  onClick={() => {
                    PageClick(
                      files.getActiveFile(ui.currentUploadId).pagesConditions[
                        files.getActivePageArrayIndex(ui.currentUploadId) - 1
                      ].pageId
                    );
                  }}
                  className="p-3 cursor-pointer disabled:opacity-30 "
                >
                  <FeatherIcon icon="chevron-left" size={18} />
                </button>
                <p className="border-l border-border_grey border-r px-3 py-2">
                  {files.getActivePageArrayIndex(ui.currentUploadId) + 1} of{" "}
                  {files.getActiveFilePageCount(ui.currentUploadId) || "--"}
                </p>
                <button
                  type="button"
                  disabled={
                    files.getActivePageArrayIndex(ui.currentUploadId) >=
                    files.getActiveFilePageCount(ui.currentUploadId) - 1
                  }
                  onClick={() => {
                    PageClick(
                      files.getActiveFile(ui.currentUploadId).pagesConditions[
                        files.getActivePageArrayIndex(ui.currentUploadId) + 1
                      ].pageId
                    );
                  }}
                  className="p-3 cursor-pointer disabled:opacity-30"
                >
                  <FeatherIcon icon="chevron-right" size={18} />
                </button>
              </div>
            </div>

            <div
              className={
                "grow w-full h-full flex items-center justify-center mt-4 overflow-auto " +
                (zoomIn ? "cursor-zoom-out" : "cursor-zoom-in")
              }
              onClick={() => setZoomIn(!zoomIn)}
            >
              <Document
                file={files.getActiveFile(ui.currentUploadId).file}
                className="flex justify-center w-full h-full"
                loading={
                  <div className=" flex items-center justify-center">
                    <CircularProgress size={25} />
                  </div>
                }
                // onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageIndex={files.getActivePageArrayIndex(ui.currentUploadId)}
                  width={600}
                  scale={zoomIn ? 1.6 : 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  loading={
                    <div className=" flex items-center justify-center">
                      <CircularProgress size={25} />
                    </div>
                  }
                />
              </Document>
            </div>
          </div>
        </div>

        <div className="bg-light_grey pt-5 py-9 relative ">
          <div className="max-h-[90vh] overflow-y-scroll pb-[80px] h-full">
            <div className="px-9 flex items-center">
              <div className="grow">
                <CreatableSelect
                  isClearable={false}
                  placeholder="Select Conditions"
                  filterOption={filterOptions}
                  components={{
                    Option: ({ children, ...rest }) => (
                      <components.Option {...rest} className="grow">
                        <div className="flex items-center">
                          {children}{" "}
                          {rest.data.isPAMT && (
                            <Chip
                              label="PAMT"
                              color="primary"
                              size="small"
                              className="ml-2"
                            />
                          )}
                        </div>
                      </components.Option>
                    ),
                  }}
                  options={conditions.getConditions}
                  isMulti={true}
                  value={files.getActiveConditions(ui.currentUploadId)}
                  onCreateOption={async (value) => {
                    conditions.updateLocalConditions([
                      ...conditions.getConditions,
                      {
                        value: null,
                        label: value,
                        isPAMT: false,
                      },
                    ]);
                    await conditions.updateConditions();
                    await conditions.getConditionsFromDatabase();

                    const newCondition = conditions.getConditions.find(
                      (c) => c.label === value
                    );
                    files.updateActiveFileConditions(ui.currentUploadId, [
                      ...files.getActiveConditions(ui.currentUploadId),
                      {
                        ...newCondition,
                      },
                    ]);
                    CheckHasConditions();
                    console.log("on create new");
                  }}
                  onChange={(updatedConditions) => {
                    if (files.multiSelect) {
                      const removedValue = files
                        .getActiveFile(ui.currentUploadId)
                        .pagesConditions[
                          files.getActivePageArrayIndex(ui.currentUploadId)
                        ].conditions.filter(
                          (existingCondition) =>
                            !updatedConditions.some(
                              ({ value }) => value === existingCondition.value
                            )
                        )[0];

                      const addedValue = updatedConditions.filter(
                        (eachValue) =>
                          !files
                            .getActiveFile(ui.currentUploadId)
                            .pagesConditions[
                              files.getActivePageArrayIndex(ui.currentUploadId)
                            ].conditions.includes(eachValue)
                      )[0];

                      if (removedValue) {
                        files.updateActiveFileConditions(
                          ui.currentUploadId,
                          updatedConditions,
                          removedValue,
                          "remove"
                        );
                      } else if (addedValue) {
                        files.updateActiveFileConditions(
                          ui.currentUploadId,
                          updatedConditions,
                          addedValue,
                          "add"
                        );
                      }

                      CheckHasConditions();
                    } else {
                      files.updateActiveFileConditions(
                        ui.currentUploadId,
                        updatedConditions
                      );
                      CheckHasConditions();
                    }
                  }}
                />
              </div>

              <div className="ml-4 min-w-[162px]">
                <Button
                  variant="contained"
                  disableElevation
                  onClick={openEditCondition}
                >
                  Edit Conditions
                </Button>
              </div>
            </div>

            {/* list */}
            <div className="mt-8  pl-9 pr-6">
              {files.multiSelect && files.selectedPages.length > 0 ? (
                <p className="label mb-4">
                  {files.selectedPages.length} page
                  {files.selectedPages.length > 1 ? "s" : ""} selected
                </p>
              ) : (
                <></>
              )}

              <p className="label">Conditions</p>
              {files.getActiveConditions(ui.currentUploadId)?.length > 0 ? (
                files
                  .getActiveConditions(ui.currentUploadId)
                  .map((condition, index) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-between items-center border-b-border_grey border-b py-3"
                      >
                        <p className="max-w-[calc(100%_-_3rem)]">
                          {condition.label}
                          {condition.isPAMT && (
                            <Chip
                              label="PAMT"
                              color="primary"
                              className="ml-2"
                            />
                          )}
                        </p>
                        {!files.multiSelect && (
                          <div
                            className="bg-primary_light rounded-full w-[2rem] h-[2rem] flex justify-center items-center cursor-pointer"
                            onClick={() => {
                              files.updateActiveFileConditions(
                                ui.currentUploadId,
                                files
                                  .getActiveConditions(ui.currentUploadId)
                                  .filter((x) => x.value !== condition.value)
                              );
                              CheckHasConditions();
                            }}
                          >
                            <FeatherIcon
                              icon="x"
                              size={14}
                              className="text-primary"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })
              ) : (
                <p
                  className="p-6 mt-10 bg-danger_light text-center rounded-full content-center"
                  style={{
                    background: "#FFF9EC",
                    color: "#E5BF6F",
                    border: "2px dashed #E5BF6F",
                  }}
                >
                  Select condition from the field on top.
                </p>
              )}
            </div>
            {/* end list */}
          </div>

          <div className="absolute left-9 bottom-7 w-[calc(100%_-_4.5rem)]">
            <div className="mt-2">
              {downloadLoading ? (
                <LoadingButton
                  loading
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  Downloading
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!canDownload}
                  size="large"
                  onClick={() => {
                    DownloadPdfs();
                  }}
                >
                  Export Documents
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TagFiles;
