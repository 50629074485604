import React, { useCallback } from "react";
import FeatherIcon from "feather-icons-react";
import { observer } from "mobx-react";
import uniqid from "uniqid";

import Logo from '../assets/Logo.png';
import { useAuth0 } from "@auth0/auth0-react";
import { ui } from "../mobx/ui";
import { files } from "../mobx/files";

const List = ({ ChangeStep }) => {
  const handleNavigateToUpdate = useCallback(() => {
    ui.setCurrentUploadId(uniqid());

    ChangeStep(1)
  }, [ChangeStep]);

  const handleNavigateToUpload = useCallback((upload) => {
    ui.setCurrentUploadId(upload.id);
    ChangeStep(1)
  }, [ChangeStep]);

  const handleDeleteUpload = useCallback((index) => {
    files.removeUpload(index);
  }, []);

  const { logout, user } = useAuth0();
  return (
    <div className="h-[100vh] w-[100vw] flex bg-[#f9f9f9]  justify-center items-start">
      <div className="flex flex-col xl:container">
        <div className="flex pt-6 items-center justify-center relative">
          <span className="flex items-center justify-center cursor-pointer absolute right-0"
            onClick={() => {
              logout()
            }}
          >
            <FeatherIcon
              icon="log-out"
              size={20}
              className="text-body_grey pr-1"
            />
            <p className="label opacity-70 duration-200 ease-in-out hover:opacity-100">Logout</p>
          </span>
          <img src={Logo} alt="" className="w-[300px]" />
        </div>
        <div className="flex flex-col p-6 mt-5 h-full bg-white rounded-lg shadow-sm">
          <div className="flex justify-between items-center w-full">
            <h2 className="text-3xl label">List of uploads</h2>
            <button className="flex  items-center bg-green text-white uppercase hover:bg-green_shade duration-200 ease-in-out p-3 rounded-lg" onClick={handleNavigateToUpdate}>
              <FeatherIcon
                icon="plus-circle"
                size={24}
                className="text-white mr-2"
              /> new upload</button>
          </div>
        </div>
        <div className="mt-10">
          {files.AllUploads(user.sub).map(upload => {
            return (
              <div key={upload.id} className="flex bg-white items-center  shadow-md	 duration-200 ease-in-out hover:shadow-lg rounded-lg justify-between mb-3 px-6 py-2">
                <span onClick={() => handleNavigateToUpload(upload)} className="label cursor-pointer w-[100%] ">{upload.patientName}</span>

                <button onClick={() => handleDeleteUpload(upload.id)} className="flex bg-[#F3B7B7] text-[#DC2626] px-2 py-1 items-center rounded-lg duration-200 ease-in-out hover:text-[#B72020] hover:bg-[#ED9292]">
                  <FeatherIcon
                    icon="trash"
                    size={20}
                    className="flex items-center pr-1"
                  /> delete</button>
              </div>
            )
          })}

         

          {files.AllUploads(user.sub).length === 0 && (
            <div className="flex bg-white items-center  shadow-md	 rounded-lg justify-between mb-3 px-6 py-2">
              <p className="text-lg">No upload performed.</p>
            </div>
          )}

        </div>
      </div>
    </div>

  )
}

export default observer(List);
