import React, { useEffect } from "react";
import UploadFiles from "../views/UploadFiles";
import TagFiles from "../views/TagFiles";

import { ui } from '../mobx/ui';
import { observer } from "mobx-react";
import List from "../views/List";


const Home = () => {
 
  const ChangeStep = (step) => {
    ui.setActivePage(step);
  };

  useEffect(() => {
    if(!ui.getActivePage) {
      ChangeStep(0);
    } else if(ui.getActivePage === 2) {
      ChangeStep(1);
    }
  }, []);


  return (
    <>
      {ui.getActivePage === 0 && <List ChangeStep={ChangeStep} />}
      {ui.getActivePage === 1 && <UploadFiles ChangeStep={ChangeStep} />}
      {ui.getActivePage === 2 && <TagFiles ChangeStep={ChangeStep} />}
    </>
  );
};
export default observer(Home);
