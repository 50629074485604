import { makeAutoObservable } from "mobx";

import { makePersistable } from "mobx-persist-store";
import localForage from "localforage";

class Ui {
  constructor() {
    this.activePage = 0;
    this.currentUploadId = "";
    makeAutoObservable(this);
    makePersistable(this, {
      name: "ui",
      properties: ["activePage", "currentUploadId"],
      storage: localForage,
    });
  }

  setCurrentUploadId(id) {
    this.currentUploadId = id;
  }

  setActivePage(index) {
    this.activePage = index;
  }

  get getActivePage() {
    return this.activePage;
  }

  get getCurrentUploadId() {
    return this.currentUploadId;
  }
}

export const ui = new Ui();
