import { makeAutoObservable, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import { makePersistable } from "mobx-persist-store";
import localForage from "localforage";

// DEMO DATA
// import uniqid from "uniqid";
// import DemoPDF from "../docs/demo.pdf";
// import DemoPDF2 from "../docs/sample.pdf";

/*
const DataArray = [
  {
    file: DemoPDF,
    num: 2,
    name: "demo.pdf",
    id: uniqid(),
    conditions: [],
    // pagesConditions: new Array(2).fill([]),
    pagesConditions: [
      {
        pageId: uniqid(),
        conditions: [],
      },
      {
        pageId: uniqid(),
        conditions: [],
      },
      {
        pageId: uniqid(),
        conditions: [],
      },
      {
        pageId: uniqid(),
        conditions: [],
      },
    ],
  },
  {
    file: DemoPDF2,
    num: 2,
    name: "demo2.pdf",
    id: uniqid(),
    conditions: [],
    // pagesConditions: new Array(2).fill([]),
    pagesConditions: [
      {
        pageId: uniqid(),
        conditions: [],
      },
      {
        pageId: uniqid(),
        conditions: [],
      },
    ],
  },
];
*/

class Files {
  constructor() {
    this.uploads = [];

    this.patientName = "";
    this.activeFileIndex = [];
    this.exportFiles = [];
    this.exportFilesInfo = [];

    this.loadedFiles = [];
    this.allFilesLoaded = false;
    this.selectedPages = [];
    this.multiSelect = false;

    makeAutoObservable(this);

    makePersistable(this, {
      name: "files",
      properties: ["uploads", "activeFileIndex"],
      storage: localForage,
    });
  }

  checkAllFilesLoaded(currentUploadId) {
    const loaded = this.getFiles(currentUploadId).every((f) => {
      const plainFile = toJS(f);
      return !!plainFile.file && !!plainFile.file.name;
    });
    this.allFilesLoaded = loaded; // check if any of the files are missing
  }

  updateMultiSelect(enable) {
    this.updateSelectedPages(this.selectedPages.filter(() => false));
    this.multiSelect = enable;
  }

  addFiles(currentUploadId, userId, file) {
    const fileIndex = this.getFiles(currentUploadId).findIndex(
      (f) => f.name === file.name
    );
    if (fileIndex > -1) {
      const existingFile = toJS(this.getFiles(currentUploadId)[fileIndex]);
      existingFile.file = file.file;
      const newList = [...this.getFiles(currentUploadId)];
      newList.splice(fileIndex, 1, existingFile);

      this.updateFiles(currentUploadId, userId, newList);
    } else {
      const newList = [...this.getFiles(currentUploadId), file];
      this.updateFiles(currentUploadId, userId, newList);
    }

    this.checkAllFilesLoaded(currentUploadId);
  }

  updateFiles(currentUploadId, userId, files) {
    const index = this.uploads.map((file) => file.id).indexOf(currentUploadId);

    if (index === -1) {
      this.uploads.push({
        id: currentUploadId,
        user: userId,
        files,
      });
    } else {
      this.uploads[index] = {
        ...this.uploads[index],
        files,
      };
    }
  }

  updateActiveFile(activeFile) {
    this.activeFileIndex = activeFile;
  }

  updatePatientName(currentUploadId, userId, patientName) {
    const index = this.uploads.map((file) => file.id).indexOf(currentUploadId);

    if (index === -1) {
      this.uploads.push({
        id: currentUploadId,
        user: userId,
        patientName,
      });
    } else {
      this.uploads[index] = {
        ...this.uploads[index],
        patientName,
      };
    }
  }

  updateActiveFileConditions(
    currentUploadId,
    conditions,
    targetValue = null,
    triger = null
  ) {
    if (this.multiSelect) {
      for (let index = 0; index < this.selectedPages.length; index++) {
        const pageIndex = this.getActiveFile(
          currentUploadId
        ).pagesConditions.findIndex(
          (page) => page.pageId === this.selectedPages[index]
        );

        // new
        if (triger == "add") {
          if (
            this.getActiveFile(currentUploadId).pagesConditions[
              pageIndex
            ].conditions.some(
              (condition) => condition.value === targetValue.value
            ) == false
          ) {
            this.getActiveFile(currentUploadId).pagesConditions[
              pageIndex
            ].conditions = [
              ...this.getActiveFile(currentUploadId).pagesConditions[pageIndex]
                .conditions,
              {
                ...targetValue,
              },
            ];
          }
        }

        if (triger == "remove") {
          if (
            this.getActiveFile(currentUploadId).pagesConditions[
              pageIndex
            ].conditions.some(
              (condition) => condition.value === targetValue.value
            ) == true
          ) {
            const targetValueIndex = this.getActiveFile(
              currentUploadId
            ).pagesConditions[pageIndex].conditions.findIndex(
              (condition) => condition.value === targetValue.value
            );

            let tempArr = [
              ...this.getActiveFile(currentUploadId).pagesConditions[pageIndex]
                .conditions,
            ];
            tempArr.splice(targetValueIndex, 1);
            this.getActiveFile(currentUploadId).pagesConditions[
              pageIndex
            ].conditions = tempArr;
          }
        }
        // end new
      }
    } else {
      this.getActiveFile(currentUploadId).pagesConditions[
        this.getActivePageArrayIndex(currentUploadId)
      ].conditions = conditions;
    }
  }

  updateSelectedPages(selected) {
    this.selectedPages = selected;
  }

  updateExportFiles(exportFiles) {
    this.exportFiles = exportFiles;
  }

  getFiles = computedFn((currentUploadId) => {
    const index = this.uploads.findIndex(
      (upload) => upload.id === currentUploadId
    );

    if (index !== -1) {
      return this.uploads[index].files;
    }

    return [];
  });

  removeUpload = computedFn((id) => {
    const index = this.uploads.findIndex((upload) => upload.id === id);
    this.uploads.splice(index, 1);
  });

  AllUploads = computedFn((userId) => {
    return this.uploads.filter((upload) => upload.user);
  });

  getPatientName = computedFn((currentUploadId) => {
    const index = this.uploads.map((file) => file.id).indexOf(currentUploadId);

    return index === -1 ? "" : this.uploads[index].patientName;
  });

  getActiveFileArrayIndex = computedFn((currentUploadId) => {
    const index = this.uploads.findIndex(
      (upload) => upload.id === currentUploadId
    );

    return this.uploads[index]
      .map((file) => file.id)
      .indexOf(this.activeFileIndex.fileId);
  });

  getActiveFile = computedFn((currentUploadId) => {
    const index = this.uploads.findIndex(
      (upload) => upload.id === currentUploadId
    );

    const currentFile = this.uploads[index]?.files.find(
      (file) => file.id === this.activeFileIndex.fileId
    );

    return currentFile ? currentFile : [];
  });

  getActiveConditions = computedFn((currentUploadId) => {
    const page = this.getActivePage(currentUploadId);
    return page?.conditions;
  });

  getActiveFilePageCount = computedFn((currentUploadId) => {
    const currentPage = this.getActiveFile(currentUploadId);
    return currentPage.pagesConditions ? currentPage.pagesConditions.length : 0;
  });

  getActivePage = computedFn((currentUploadId) => {
    const currentPage = this.getActiveFile(currentUploadId);
    const page = currentPage.pagesConditions?.find(
      (condition) => condition.pageId === this.activeFileIndex.pageId
    );
    return page;
  });

  getActivePageArrayIndex = computedFn((currentUploadId) => {
    const currentFile = this.getActiveFile(currentUploadId);
    const index = currentFile.pagesConditions?.findIndex(
      (condition) => condition.pageId === this.activeFileIndex.pageId
    );
    return index;
  });

  getIndexPageByPageAndFileId = computedFn(
    (currentUploadId, fileId, pageId) => {
      const index = this.uploads.findIndex(
        (upload) => upload.id === currentUploadId
      );

      const currentFile = this.uploads[index].files.find(
        (file) => file.id === fileId
      );

      const pageIndex = currentFile.pagesConditions.findIndex(
        (page) => page.pageId === pageId
      );

      return pageIndex;
    }
  );

  get getExportFilesInfo() {
    return this.exportFilesInfo;
  }

  // QUESTION: should i put it here?
  formatExportFilesInfo = computedFn((currentUploadId) => {
    this.exportFilesInfo = [];

    this.getFiles(currentUploadId).forEach((file) => {
      file.pagesConditions.forEach((page) => {
        page.conditions.forEach((condition) => {
          //If the condition has already existed in filesArray
          if (
            this.exportFilesInfo.filter(
              (file) => file.conditionId === condition.value
            ).length > 0
          ) {
            const targetFileIndex = this.exportFilesInfo
              .map((file) => file.conditionId)
              .indexOf(condition.value);

            // check if file is already in fileArray

            // TODO: change to array, can be in multiple condiotns
            let fileIsInArray = this.exportFilesInfo.some((eachCondition) => {
              if (eachCondition.conditionId === condition.value) {
                return eachCondition.includedFiles.some((eachFile) => {
                  return eachFile.fileId === file.id;
                });
              }

              return false;
            });

            if (fileIsInArray) {
              const targetPageFileIndex = this.exportFilesInfo[
                targetFileIndex
              ].includedFiles
                .map((eachFile) => eachFile.fileId)
                .indexOf(file.id);

              this.exportFilesInfo[targetFileIndex].includedFiles[
                targetPageFileIndex
              ].pages.push(page.pageId);
            } else {
              this.exportFilesInfo[targetFileIndex].includedFiles = [
                ...this.exportFilesInfo[targetFileIndex].includedFiles,
                {
                  fileId: file.id,
                  file: file.file,
                  fileUrl: file.fileUrl,
                  pages: [page.pageId],
                },
              ];
            }
          } else {
            this.exportFilesInfo = [
              ...this.exportFilesInfo,
              {
                conditionName: condition.label,
                conditionId: condition.value,
                conditionIsPAMT: condition.isPAMT,
                includedFiles: [
                  {
                    fileId: file.id,
                    file: file.file,
                    fileUrl: file.fileUrl,
                    pages: [page.pageId],
                  },
                ],
              },
            ];
          }
        });
      });
    });

    return this.exportFilesInfo;
  });

  get getExportFiles() {
    return this.exportFiles;
  }
}

// Files
export const files = new Files();
