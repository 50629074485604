import { useState, useRef, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";

const ConditionLabel = ({
  index,
  val,
  PAMT = false,
  onChangeLabel,
  onChangePAMT,
  onRemoveLabel,
  newCondition,
}) => {
  const [value, setValue] = useState(val);
  const [isPAMT, setIsPAMT] = useState(PAMT);

  const deleteRef = useRef();

  useEffect(() => {
    if (newCondition) {
      deleteRef.current.scrollIntoView();
    }
  }, []);

  const valueChanged = (val) => {
    setValue(val);
    onChangeLabel(index, val);
  };

  const PAMTChanged = (val) => {
    setIsPAMT(val);
    onChangePAMT(index, val);
  };

  const removeLabel = () => {
    deleteRef.current.style.display = "none";
    onRemoveLabel(index);
  };

  return (
    <div className="flex mb-4" ref={deleteRef}>
      <TextField
        id="label"
        label="Label"
        type="text"
        fullWidth
        variant="standard"
        value={value}
        onChange={(e) => valueChanged(e.target.value)}
      />
      <FormControlLabel
        control={
          <Checkbox checked={isPAMT} onChange={() => PAMTChanged(!isPAMT)} />
        }
        label="PAMT"
      />
      <IconButton color="error" onClick={removeLabel}>
        <FeatherIcon icon="trash-2" />
      </IconButton>
    </div>
  );
};

export default ConditionLabel;
