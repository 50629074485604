import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore/lite";

// const options = [
//   { value: uniqid(), isPAMT: false, label: "Achilles tendinopathy and bursitis" },
//   { value: uniqid(), isPAMT: false, label: "Chondromalacia patella" },
//   { value: uniqid(), isPAMT: false, label: "Cut, stab, abrasion and laceration" },
//   { value: uniqid(), isPAMT: false, label: "Dislocation" },
//   { value: uniqid(), isPAMT: false, label: "Fracture" },
//   { value: uniqid(), isPAMT: false, label: "Internal derangement of the knee" },
//   { value: uniqid(), isPAMT: false, label: "Intervertebral disc prolapse" },
//   { value: uniqid(), isPAMT: false, label: "Joint instability" },
//   { value: uniqid(), isPAMT: false, label: "Labral tear" },
//   { value: uniqid(), isPAMT: false, label: "Lumbar spondylosis" },
//   { value: uniqid(), isPAMT: false, label: "Non-melanotic malignant neoplasm of the skin" },
//   { value: uniqid(), isPAMT: false, label: "Osteoarthritis" },
//   { value: uniqid(), isPAMT: false, label: "Plantar fasciitis" },
//   { value: uniqid(), isPAMT: false, label: "Rotator cuff syndrome" },
//   { value: uniqid(), isPAMT: false, label: "Sensorineural hearing loss" },
//   { value: uniqid(), isPAMT: false, label: "Shin splints" },
//   { value: uniqid(), isPAMT: false, label: "Solar keratosis" },
//   { value: uniqid(), isPAMT: false, label: "Sprain and strain" },
//   { value: uniqid(), isPAMT: false, label: "Thoracic spondylosis" },
//   { value: uniqid(), isPAMT: false, label: "Tinnitus" },
// ];

class Conditionss {
  constructor() {
    this.conditions = [];
    this.getConditionsFromDatabase();
    makeAutoObservable(this);
  }

  async getConditionsFromDatabase() {
    const snapshot = await getDocs(
      query(collection(db, "conditions"), orderBy("label"))
    );
    this.conditions = snapshot.docs.map((d) => d.data());
  }

  updateLocalConditions(conditions) {
    this.conditions = conditions;
  }

  async updateConditions() {
    const conditions = this.getConditions;
    for (let index = 0; index < conditions.length; index++) {
      // new line
      if (!conditions[index].value && !conditions[index].pendingDelete) {
        const docRef = await addDoc(collection(db, "conditions"), {
          value: null,
          label: conditions[index].label,
          isPAMT: conditions[index].isPAMT,
        });

        await updateDoc(docRef, "value", docRef.id);
      } else if (conditions[index].pendingDelete && conditions[index].value) {
        // has pending delete
        await deleteDoc(doc(db, `conditions/${conditions[index].value}`));
      } else if (conditions[index].pendingUpdate) {
        // has pending update
        if (conditions[index].newCondition) {
          const docRef = await addDoc(collection(db, "conditions"), {
            value: null,
            label: conditions[index].label,
            isPAMT: conditions[index].isPAMT,
          });
          await updateDoc(docRef, "value", docRef.id);
        } else {
          const d = {
            value: conditions[index].value,
            label: conditions[index].label,
            isPAMT: conditions[index].isPAMT,
          };
          await updateDoc(doc(db, `conditions/${d.value}`), d);
        }
      }
    }
  }

  get getConditions() {
    return this.conditions;
  }

  getConditionById = computedFn((id) => {
    return this.conditions.find((condition) => condition.value === id);
  });
}

// Conditions
export const conditions = new Conditionss();
