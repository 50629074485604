import React, { useCallback, useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { PDFDocument } from "pdf-lib";
import uniqid from "uniqid";

import Logo from "../assets/Logo.png";

import { Button, Alert, TextField } from "@mui/material";

import FileToArrayBuffer from "../functions/FileToArrayBuffer";

// mobx
import { files } from "../mobx/files";
import { ui } from "../mobx/ui";
import { observer } from "mobx-react";
import { useAuth0 } from "@auth0/auth0-react";

const UploadFiles = ({ ChangeStep }) => {
  const { user } = useAuth0();

  const [patientName, setPatientName] = useState(
    files.getPatientName(ui.getCurrentUploadId)
  );

  const [duplicateFile, setDuplicateFile] = useState({
    duplicate: false,
    name: "",
  });

  const GetPdfPageNum = async (pdfFile) => {
    const existingPdfBytes = await FileToArrayBuffer(pdfFile);

    const pdfDoc = await PDFDocument.load(existingPdfBytes, {
      ignoreEncryption: true,
    });

    const pages = pdfDoc.getPages();

    // const base64DataUri = await pdfDoc.saveAsBase64({ dataUri: true })
    const base64DataUri = "";

    return { pages, base64DataUri };
  };

  const DisplayPdfList = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach(async (file, index) => {
        const { pages, base64DataUri } = await GetPdfPageNum(file);

        const pagesConditionsArray = new Array(pages.length)
          .fill([])
          .map((_, index) => {
            return {
              pageId: uniqid(),
              conditions: [],
            };
          });

        if (!ui.getCurrentUploadId) {
          const id = uniqid();
          ui.setCurrentUploadId(id);
        }

        files.addFiles(ui.getCurrentUploadId, user.sub, {
          file: file,
          fileUrl: base64DataUri,
          num: pages.length,
          name: file.name,
          id: uniqid(),
          pagesConditions: pagesConditionsArray,
        });
      });
    },
    [user.sub]
  );

  const RemoveCurrentList = (currentFile) => {
    files.updateFiles(
      ui.getCurrentUploadId,
      user.sub,
      files
        .getFiles(ui.getCurrentUploadId)
        .filter((file) => file.file !== currentFile)
    );
  };

  const CloseDuplicateMessage = () => {
    setDuplicateFile({ ...duplicateFile, duplicate: false });
  };

  const handlePutPatientsName = useCallback(
    (event) => {
      setPatientName(event.target.value);
    },
    [setPatientName]
  );

  const handleGoToTagPage = useCallback(() => {
    files.updatePatientName(ui.getCurrentUploadId, user.sub, patientName);

    files.updateActiveFile({
      fileId: files.getFiles(ui.getCurrentUploadId)[0].id,
      pageId: files.getFiles(ui.getCurrentUploadId)[0].pagesConditions[0]
        .pageId,
    });

    ChangeStep(2);
  }, [ChangeStep, patientName, user.sub]);

  useEffect(() => {
    files.checkAllFilesLoaded(ui.getCurrentUploadId);
  });

  const [workingDirectory, setWorkingDirectory] = useState(null);
  const openWorkingDirectory = async () => {
    const dirHandle = await window.showDirectoryPicker({
      startIn: workingDirectory || "documents",
    });

    const filesToLoad = [];
    if (!patientName) {
      setPatientName(dirHandle.name);
    }

    for await (const entry of dirHandle.values()) {
      const fileHandle = await dirHandle.getFileHandle(entry.name);
      filesToLoad.push(await fileHandle.getFile());
    }

    DisplayPdfList(filesToLoad);

    setWorkingDirectory(dirHandle);
  };

  return (
    <>
      <div className="h-[100vh] w-[100vw] flex justify-center">
        <div className="flex flex-col  xl:container">
          <div className="flex pt-6 items-center justify-center relative">
            <span
              className="flex items-center justify-center cursor-pointer absolute left-0"
              onClick={() => {
                ChangeStep(0);
              }}
            >
              <FeatherIcon
                icon="chevron-left"
                size={20}
                className="text-body_grey pr-1"
              />
              <p className="label opacity-70 duration-200 ease-in-out hover:opacity-100">
                Back to list
              </p>
            </span>
            <img src={Logo} alt="" className="w-[300px]" />
          </div>

          <div className="flex justify-between p-6 mt-5 h-[80%] bg-light_grey rounded-lg shadow-sm">
            <div className="flex justify-center items-center relative w-[65%]">
              {duplicateFile.duplicate && (
                <div className="absolute bottom-4 w-[70%] rounded-lg">
                  <Alert
                    severity="error"
                    onClose={() => {
                      CloseDuplicateMessage();
                    }}
                    className="my-3"
                  >
                    {duplicateFile.name} has already been uploaded.
                  </Alert>
                </div>
              )}

              {/* <Button onClick={openWorkingDirectory}>
                Abrir diretório de arquivos
              </Button> */}

              <div
                className="w-[450px] h-[350px]  cursor-pointer flex justify-center items-center rounded-lg shadow-xl shadow-gray-300"
                onClick={openWorkingDirectory}
                style={{ padding: 24, background: "#FFF" }}
              >
                <div
                  className="flex h-full w-full text-primary rounded-lg"
                  style={{
                    border: "2px dashed #D5DAE4",
                    background: "#F5F7FD",
                  }}
                >
                  <div className="text-center m-auto ">
                    <FeatherIcon
                      icon="upload-cloud"
                      className="mx-auto mb-1"
                      size={30}
                    />
                    <p className="label text-primary">Choose Directory</p>
                  </div>
                </div>
              </div>

              {/* <Dropzone
                onDrop={DisplayPdfList}
                accept={{ "application/pdf": [".pdf"] }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="w-[450px] h-[350px]  cursor-pointer flex justify-center items-center rounded-lg shadow-xl shadow-gray-300"
                    style={{ padding: 24, background: "#FFF" }}
                  >
                    <div
                      className="flex h-full w-full text-primary rounded-lg"
                      style={{
                        border: "2px dashed #D5DAE4",
                        background: "#F5F7FD",
                      }}
                    >
                      <div className="text-center m-auto ">
                        <input {...getInputProps()} />
                        <FeatherIcon
                          icon="upload-cloud"
                          className="mx-auto mb-1"
                          size={30}
                        />
                        <p className="label text-primary">File Upload</p>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone> */}
            </div>
            <div
              className=" flex flex-col p-6 w-[35%] h-[100%] rounded-lg shadow-xl	shadow-gray-100"
              style={{ background: "#FFF" }}
            >
              <span className="label">Documents</span>

              <div
                className="mt-9 p-2 flex flex-col w-[100%] justify-center items-center h-[100%]"
                style={{ maxHeight: "95%", overflow: "scroll" }}
              >
                {files.getFiles(ui.currentUploadId).length > 0 ? (
                  files.getFiles(ui.currentUploadId).map((file, index) => {
                    return (
                      <div
                        key={index}
                        className="flex w-[100%] mb-3 justify-between items-center border-b-border_grey border-b py-3"
                      >
                        {/* last:border-b-0 */}
                        <p className="max-w-[calc(100%_-_3rem)]">{file.name}</p>
                        <div
                          className="bg-primary_light rounded-full w-[2.2rem] h-[2.2rem] flex justify-center items-center cursor-pointer"
                          onClick={() => {
                            console.log(file.file);
                            RemoveCurrentList(file.file);
                          }}
                        >
                          <FeatherIcon
                            icon="x"
                            size={16}
                            className="text-primary"
                          />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p
                    className="p-6 bg-danger_light text-center rounded-full"
                    style={{
                      background: "#FFF9EC",
                      color: "#E5BF6F",
                      border: "2px dashed #E5BF6F",
                    }}
                  >
                    Upload some files to start.
                  </p>
                )}
              </div>
              <div className="flex flex-col justify-between bottom-7 mt-5 w-[100%] ">
                <div>
                  <span className="flex mb-5">
                    Please, input the patient name:
                  </span>
                  <TextField
                    placeholder="Patient name"
                    label="Patient name"
                    fullWidth
                    value={patientName}
                    onChange={handlePutPatientsName}
                  />
                </div>
                {!files.allFilesLoaded && (
                  <Alert
                    severity="warning"
                    className="my-3"
                  >
                    Make sure all files you will edit are located in the selected directory.
                  </Alert>
                )}

                <div className="w-[calc(100%_-_4.5rem)] mx-auto mt-6">
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    size="large"
                    disabled={
                      files.getFiles(ui.currentUploadId).length <= 0 ||
                      patientName === "" ||
                      !files.allFilesLoaded
                    }
                    onClick={handleGoToTagPage}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(UploadFiles);
