import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import { conditions } from "../mobx/conditions";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import uniqid from "uniqid";
import ConditionLabel from "./ConditionLabel";

const EditConditionList = ({ shown, handleClose, data }) => {
  const [saving, setSaving] = useState(false);
  const conditionList = data;

  // function updateCondition(field, value, condition) {
  //   let selected = conditionList.find((c) => c.value === condition.value);
  //   selected[field] = value;
  //   selected.pendingUpdate = true;
  //   conditions.updateLocalConditions(conditionList);
  // }

  function addCondition() {
    conditionList.push({
      value: uniqid(),
      label: "",
      isPAMT: false,
      newCondition: true,
    });
    conditions.updateLocalConditions(conditionList);
  }

  // function removeCondition(condition) {
  //   let selected = conditionList.find((c) => c.value === condition.value);
  //   selected.pendingDelete = true;
  //   conditions.updateLocalConditions(conditionList);
  // }

  async function handleSave() {
    conditions.updateLocalConditions(conditionList);
    setSaving(true);
    await conditions.updateConditions();
    setSaving(false);
    handleClose();
  }

  const updateConditionLabel = (index, val) => {
    conditionList[index].label = val;
    conditionList[index].pendingUpdate = true;
  };

  const updatePAMT = (index, val) => {
    conditionList[index].isPAMT = val;
    conditionList[index].pendingUpdate = true;
  };

  const removeConditionLabel = (index) => {
    conditionList[index].pendingDelete = true;
    conditions.updateLocalConditions(conditionList);
  };

  return (
    <>
      <Dialog maxWidth={"md"} fullWidth open={shown} onClose={handleClose}>
        <DialogTitle>
          <div className="flex">
            <div className="grow">Condition</div>

            <IconButton onClick={handleClose}>
              <FeatherIcon icon="x" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Edit conditions</DialogContentText>

          {conditionList
            .filter((c) => !c.pendingDelete)
            .map(
              (c, index) => (
                <ConditionLabel
                  key={index}
                  index={index}
                  val={c.label}
                  PAMT={c.isPAMT}
                  onChangeLabel={updateConditionLabel}
                  onChangePAMT={updatePAMT}
                  onRemoveLabel={removeConditionLabel}
                  newCondition={c.newCondition}
                />
              )

              // <div className="flex mb-4" key={index}>
              //   {
              //     console.log('redraw')
              //   }
              //   <TextField
              //     id="label"
              //     label="Label"
              //     type="text"
              //     fullWidth
              //     variant="standard"
              //     value={c.label}
              //     onChange={($ev) =>
              //       updateCondition("label", $ev.target.value, c)
              //     }
              //   />
              //   <FormControlLabel
              //     control={
              //       <Checkbox
              //         checked={c.isPAMT}
              //         onChange={($ev) =>
              //           updateCondition("isPAMT", $ev.target.checked, c)
              //         }
              //       />
              //     }
              //     label="PAMT"
              //   />
              //   <IconButton color="error" onClick={() => removeCondition(c)}>
              //     <FeatherIcon icon="trash-2" />
              //   </IconButton>
              // </div>
            )}
        </DialogContent>
        <DialogActions>
          <div className="flex justify-between w-full">
            <Button
              variant="contained"
              disableElevation
              color="secondary"
              onClick={addCondition}
              startIcon={<FeatherIcon icon="plus" size="18" />}
            >
              New condition
            </Button>
            <div>
              <span className="mr-2">
                <Button onClick={handleClose} disableElevation>
                  Close
                </Button>
              </span>

              <Button
                variant="contained"
                disableElevation
                onClick={handleSave}
                disabled={saving}
              >
                {saving ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditConditionList;
